.modalStyles {
    display: flex !important;
    justify-content: center;
    align-items: center;
    max-width: 50% !important;
    max-height: 50% !important;
}

.upload-modal-container {
    display: flex;
    flex-direction: column;
    // min-width: 50%;
    // min-height: 50%;
    padding: 25px;

    .select-container {
        margin: 25px 0;

        .label {
            margin-right: 5px;
        }
    }

    .buttons-container {
        display: flex;
        justify-content: center;
        margin-top: 25px;

        .button {
            padding: 5px 7.5px;
            border-radius: 10px;
            width: 100px;
            background-color: #13357b;
            color: white;
            border: none;
        }
        .button:first-child {
            margin-right: 10px;
        }
    }
}
