.modal-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;

    .modal {
        overflow-y: auto;
        max-width: 80%;
        max-height: 60%;
        background: white;
        border-radius: 6px;
        position: relative;
        display: block;
    }
}
// .dark-background {
//     background: $color-sixty-percent-black;
// }
