body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.custom-gray-bg {
  background-color: #e8e3e3;
  padding: 6px 10px;
}
.custom-min-height{
  min-height: 500px;
}
.chart_responsive {
  @media (max-width: 1800px) {
    .col-xl-7,
    .col-xl-5 {
      width: 100%;
    }
  }
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}
.container-fluid:after {
  clear: both;
}

.custom-modal .modal-dialog {
  width: 95% !important; /* Adjust the width as needed */
  max-width: 95% !important; /* Ensure the modal doesn't exceed this width */
}

