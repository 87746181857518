
@import './mixin.scss';

@mixin media-above($size) {
  @media (min-width: $size) {
    @content;
  }
}

.header-margin {
  margin-top: 90px;

  @include media-below(md) {
    margin-top: 80px;
  }
}

.header {
  position: fixed;
  z-index: 1000;
  background-color: #13357b;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 90px;
  @include media-below(md) {
    height: 80px;
  }

  &.-fixed {
    position: absolute !important;
  }

  .is-menu-opened-hide {
   // transition: opacity 0.4s $easeOutQuart;
  }

  &.is-menu-opened {
    background-color: white !important;
    border-bottom: 1px solid var(--color-border);

    .is-menu-opened-hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.is-sticky {
    margin: 0 !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .row {
    flex-wrap: nowrap;
  }

  &__container {
    width: 100%;
  }

  &__container-1500 {
    max-width: 1560px;
  }

  .header-logo {
    position: relative;

    img {
      width: 140px;

      @include media-below(md) {
        max-width: 120px;
      }
    }

    img:nth-child(1) {
    }

    img:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }

    &.is-logo-dark {
      img:nth-child(1) {
        opacity: 0;
      }
      img:nth-child(2) {
        opacity: 1;
      }
    }
  }





  &.-dashboard {
    .header__container {
      display: flex;
      align-items: center;

      > .row {
        width: 100%;
      }
    }

    .-left-side {
      flex-shrink: 0;
      width: calc(var(--dashboard-width) - 30px);

      @include media-below(lg) {
        width: auto;
      }
    }
  }
}



.mobile-col {
  @media (max-width: 767px) {
    width: 100%;
    .d-flex.items-center {
      justify-content: space-between;
    }
    .mr-20 {
      order: 2;
      margin-right: 0 !important;
    }
    .header-logo {
      order: 1;
    }
  }
}

// active menu
.menu__nav li.current {
  color: var(--color-blue-1);
}
.mega__grid div.current {
  color: var(--color-blue-1);
}

.header .header-menu .menu-item-has-children .subnav a,
.header .header-menu .menu-item-has-children .mega a {
  font-weight: 400;
}
.bg-color{
background-color: #13357b;
color:white;
border:none;
}